import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Icon, Text } from '~/components/blocks';
import { useFeatureFlag } from '~/hooks/use-feature-flag';

const BoldSpan = styled('span')(() =>
  css({
    fontWeight: 'bold',
  }),
);

const Link = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

type Props = {
  uberDeliveryFee: number;
};

export const UberDeliveryFeeInfo = React.memo((props: Props) => {
  const { uberDeliveryFee } = props;
  const theme = useTheme();
  const displayUberFreePromotion = useFeatureFlag('Issue9813-promotion');

  if (displayUberFreePromotion) {
    return (
      <Alert status="info" marginTop={theme.space.m}>
        <Text>
          キャンペーン適用対象につき、今回の当日配達利用料は<BoldSpan>無料</BoldSpan>
          となります（
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://intercom.help/pharms/ja/articles/9765203"
          >
            キャンペーン詳細
          </Link>
          <Icon
            icon="blank"
            size="m"
            marginLeft={theme.space.s}
            marginRight={theme.space.s}
            color="linkBlue"
          />
          ）
        </Text>
      </Alert>
    );
  }

  return (
    <Alert status="info" marginTop={theme.space.m}>
      <Text>
        今回の配達利用料は<BoldSpan>{uberDeliveryFee}円</BoldSpan>です
      </Text>
    </Alert>
  );
});

UberDeliveryFeeInfo.displayName = 'UberDeliveryFeeInfo';
