import React from 'react';
import { useRecoilValue } from 'recoil';

import { followupMessageErrorState } from '~/state/layouts/SharedAppShell/atoms';

import { FollowupMessageNotification } from './FollowupMessageErrorNotification';
import { useSubscriptionFollowupMessage } from './use-subscription-followup-message';

export const FollowupMessageNotifications = () => {
  const { notifications } = useRecoilValue(followupMessageErrorState);
  const { handleClose } = useSubscriptionFollowupMessage();

  return (
    <>
      {notifications.map((notification) => (
        <FollowupMessageNotification
          key={notification.organizationNotificationId}
          followupMessage={notification.followupMessage}
          notifiedAt={notification.createdAt}
          onClose={handleClose}
        />
      ))}
    </>
  );
};
