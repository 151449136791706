import { rgba } from 'polished';

import { ColorProperty, SizeProperty } from './types';

/**
 * 可能ならばサイズをもつプロパティは`SizeProperty`型で持って規則性を持たせたい
 * lighten, darken使う場合 import { lighten, darken, rgba } from 'polished';
 */

// Pharmsで利用する色
// patientと接頭辞がついているものは、患者向けの色
export const ColorPalette = {
  green: '#3DA466',
  lime: '#B1DBC2',
  lightGreen: '#EEF7F1',
  pink: '#E94781',
  rose: '#F8C8D9',
  lightPink: '#FDF0F4',
  blue: '#6692D3',
  aquaBlue: '#C2D3ED',
  lightBlue: '#F0F4FB',
  yellow: '#EDAB12',
  honey: '#F4CD71',
  lightYellow: '#FDF7E7',
  cyan: '#64C5BF',
  linkBlue: '#1A0DAB',
  black: '#223344',
  navy: '#445566',
  grey: '#778899',
  white: '#ffffff',
  patientRed: '#E51C51',
  patientLinkBluePurple: '#243E91',
  patientPink: '#FFF4F5',
  patientNavy: '#445566',
  patientCyanBlue: '#223344',
} as const;

// Pharmsで利用するモノカラー
// patientと接頭辞がついているものは、患者向けの色
export const MonoColor = {
  black: ColorPalette.black,
  grey01: '#778899',
  grey02: '#323538',
  grey03: '#D2D7E1',
  grey04: '#F2F4F6',
  grey05: '#C1C2CC',
  grey06: '#EAECEE',
  patientGrey01: '#E5E9F0',
  white: ColorPalette.white,
} as const;

const mediaQueries = {
  sp: 'screen and (max-width: 640px)',
  // iPad用：portait（縦向き）、landscape（横向き）
  portrait: 'screen and (orientation:portrait) and (max-width: 768px)',
  landscape: 'screen and (max-width: 1024px)',
  tablet: 'screen and (max-width: 1300px)',
  pc: 'screen and (min-width: 1301px)',
};

export const space: SizeProperty = {
  xxs: '',
  xs: '2px',
  s: '4px',
  m: '8px',
  l: '16px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
};

export const fontSizes: SizeProperty = {
  xxs: '1.0rem',
  xs: '1.2rem',
  s: '1.4rem',
  m: '1.6rem',
  l: '2.0rem',
  xl: '2.4rem',
  xxl: '3.2rem',
  xxxl: '4.0rem',
};

const colors = {
  colorPallete: {
    ...ColorPalette,
    ...MonoColor,
  },
  text: {
    default: ColorPalette.black,
    primary: ColorPalette.green,
    secondary: ColorPalette.pink,
    white: ColorPalette.white,
    entrytitle: MonoColor.grey01,
    placeholder: MonoColor.grey05,
    error: ColorPalette.pink,
    link: ColorPalette.linkBlue,
    patientLinkBluePurple: ColorPalette.patientLinkBluePurple,
  } as ColorProperty,
  background: {
    default: ColorPalette.white,
    primary: ColorPalette.green,
    secondary: ColorPalette.pink,
    blue: ColorPalette.blue,
    grey: MonoColor.grey01,
    black: ColorPalette.black,
    yellow: ColorPalette.yellow,
    shadow: rgba(ColorPalette.black, 0.2),
    shadowgrey: rgba(ColorPalette.navy, 0.1),
    shadowgreen: rgba(ColorPalette.green, 0.2),
    disabled: MonoColor.grey03,
    bg: MonoColor.grey04,
    transparent: 'transparent',
    alert: ColorPalette.lightPink,
    warning: ColorPalette.lightYellow,
    info: ColorPalette.lightBlue,
    success: ColorPalette.lightGreen,
    patientPrimary: ColorPalette.patientRed,
    patientSecondary: ColorPalette.patientPink,
    patientNavy: ColorPalette.patientNavy,
    patientCyanBlue: ColorPalette.patientCyanBlue,
  },
  border: {
    default: MonoColor.grey03,
    primary: ColorPalette.green,
    secondary: ColorPalette.pink,
    black: ColorPalette.black,
    white: ColorPalette.white,
    grey: MonoColor.grey06,
    grey01: MonoColor.grey01,
    blue: ColorPalette.blue,
    patientPrimary: ColorPalette.patientRed,
    patientNavy: ColorPalette.patientNavy,
  },
};

const fonts = {
  default: 'Lato, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif',
};

const fontWeights = {
  default: 400,
  bold: 600,
};

const lineHeights = {
  s: '1',
  m: '1.6',
  l: '2.2',
};

const letterSpacings = {};

const sizes = {};

const borders = {
  default: `1px solid ${colors.border.default}`,
  primary: `1px solid ${colors.border.primary}`,
  secondary: `1px solid ${colors.border.secondary}`,
  black: `1px solid ${colors.border.black}`,
  transparent: `1px solid transparent`,
  error: `1px solid ${ColorPalette.pink}`,
  tableNoline: `4px solid ${MonoColor.grey04}`,
  warning: `1px solid ${ColorPalette.honey}`,
  info: `1px solid ${ColorPalette.aquaBlue}`,
  alert: `1px solid ${ColorPalette.rose}`,
  success: `1px solid ${ColorPalette.lime}`,
  grey: `1px solid ${colors.border.grey}`,
  grey01: `1px solid ${colors.border.grey01}`,
  patientPrimary: `1px solid ${colors.border.patientPrimary}`,
  patientNavy: `1px solid ${colors.border.patientNavy}`,
};

const borderWidths = {};

const borderStyles = {};

const radii = {
  default: '4px',
  modal: '8px',
  circle: '50%',
  half: '100px',
};

const shadows = {
  default: `0 2px 8px ${colors.background.shadow}`,
  primary: `0 2px 8px ${colors.background.shadowgreen}`,
  secondary: `2px 8px 24px ${colors.background.shadowgrey}`,
};

const zIndices = {
  header: 4,
  selectBox: 5,
  drawer: 6,
  toast: 6,
  popup: 6,
  tooltip: 10,
  snackbar: 7,
  modalOverlay: 7,
  modalContainer: 8,
  loaderOnFullScreen: 9,
};

const transitions = {
  fast: '0.1s',
  default: '0.2s',
};

const modals = {
  xs: '360px',
  s: '480px',
  m: '720px',
  l: '960px',
  xl: '1024px',
  fill: '100%',
};

const layouts = {
  appHeader: {
    height: '58px',
  },
  responsiveMenu: {
    height: '64px',
  },
};

export const theme = {
  mediaQueries,
  space,
  fontSizes,
  colors,
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  sizes,
  borders,
  borderWidths,
  borderStyles,
  radii,
  shadows,
  zIndices,
  transitions,
  modals,
  layouts,
} as const;
