import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Button, Flex, Icon, Text, Tooltip } from '~/components/blocks';
import {
  AppointmentStatus,
  PatientProfileFooterAppointmentFragment,
  UberDeliveryStatus,
} from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { ChangeDeliveryMethodModal } from '~/state/partials/change_delivery_method_modal/types';
import { chargeModalState } from '~/state/partials/charge_modal/atoms';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { RequestUberDeliveryModalState } from '~/state/reception/types';

import { useFinishAppointment } from './use-finish-appointment';

type Props = {
  appointment: PatientProfileFooterAppointmentFragment;
};

const RequestButton = styled(Button)(() =>
  css({
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
      pointerEvents: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
);

const DisabledButton = styled(Button)(() =>
  css({
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
      pointerEvents: 'inherit',
    },
  }),
);

export const SameDayDeliveryButtons = React.memo((props: Props) => {
  const { appointment } = props;
  const uberDelivery = appointment.uberDelivery;
  const { finishing, handleFinish } = useFinishAppointment(appointment.id);
  const { loading, enable: enabledUber } = useUberOrganizationSetting();

  const handleRequestDelivery = useRecoilCallback(
    ({ set }) =>
      () =>
        set(requestUberDeliveryModalState, (_state: RequestUberDeliveryModalState) => ({
          ..._state,
          isOpen: true,
          appointmentId: appointment.id,
        })),
    [appointment],
  );

  const handleChangeDeliveryMethod = useRecoilCallback(
    ({ set }) =>
      () =>
        set(changeDeliveryMethodState, (_state: ChangeDeliveryMethodModal) => ({
          ..._state,
          isOpen: true,
          appointmentId: appointment.id,
        })),
    [appointment],
  );

  // - 未会計 -> 会計済(配送待ち)
  const handelOpenChargeModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chargeModalState, {
          appointmentId: appointment.id,
          isOpen: true,
        });
      },
    [appointment.id],
  );

  if (loading) {
    return null;
  }

  if (!enabledUber && !uberDelivery) {
    return (
      <Button use="base" wide="fill" size="l" onClick={handleChangeDeliveryMethod}>
        受け渡し方法を変更
      </Button>
    );
  }

  if (!uberDelivery) {
    return (
      <RequestButton use="primary" wide="fill" size="l" onClick={handleRequestDelivery}>
        集荷依頼に進む
      </RequestButton>
    );
  }

  if (uberDelivery.status === UberDeliveryStatus.UberSupportCanceled) {
    return (
      <Tooltip
        content={
          <Flex alignItems="center" justifyContent="center" color="white">
            <Icon icon="attention" size="m" />
            <Text color="white">状況確認中です。完了するまで操作できません</Text>
          </Flex>
        }
      >
        <DisabledButton
          use={appointment.status === AppointmentStatus.Charged ? 'base' : 'secondary'}
          wide="fill"
          size="l"
          disabled
        >
          {appointment.status === AppointmentStatus.Charged ? '完了' : '会計'}
        </DisabledButton>
      </Tooltip>
    );
  }

  if (uberDelivery.status === UberDeliveryStatus.Unfulfilled) {
    return (
      <RequestButton use="primary" wide="fill" size="l" onClick={handleRequestDelivery}>
        集荷依頼に進む
      </RequestButton>
    );
  }

  if (
    uberDelivery.status === UberDeliveryStatus.Pending &&
    uberDelivery.isTimeElapsedNotification
  ) {
    return (
      <Button use="base" wide="fill" size="l" onClick={handleChangeDeliveryMethod}>
        受け渡し方法を変更
      </Button>
    );
  }

  if (
    uberDelivery.status === UberDeliveryStatus.Pending &&
    !uberDelivery.isTimeElapsedNotification
  ) {
    return (
      <RequestButton use="primary" wide="fill" size="l" disabled>
        集荷依頼に進む
      </RequestButton>
    );
  }

  if (appointment.status === AppointmentStatus.WaitingForCharge) {
    return (
      <Button use="secondary" wide="fill" size="l" onClick={handelOpenChargeModal}>
        会計
      </Button>
    );
  }

  if (uberDelivery.status === UberDeliveryStatus.Canceled) {
    return (
      <Button use="base" wide="fill" size="l" loading={finishing} onClick={handleFinish}>
        完了
      </Button>
    );
  }
});

SameDayDeliveryButtons.displayName = 'SameDayDeliveryButtons';
