import { AppointmentCancellationReason, AppointmentStatus } from '~/graphql';

type CanceledByType = '薬局' | '患者' | '不明';

export const translateReason = (
  reason: AppointmentCancellationReason,
  status?: AppointmentStatus,
  hasWaitingForChargeHistory?: boolean,
): { canceledBy: CanceledByType; detail: string } => {
  switch (reason) {
    case AppointmentCancellationReason.Pat:
      if (status === AppointmentStatus.Noshow) {
        return hasWaitingForChargeHistory
          ? { canceledBy: '薬局', detail: '患者都合でのキャンセル' }
          : { canceledBy: '薬局', detail: '患者が来店せず、連絡もとれない' };
      }
      return { canceledBy: '薬局', detail: '有効期限内に原本が届かなかった' };
    case AppointmentCancellationReason.PatCpp:
      return { canceledBy: '患者', detail: '患者からキャンセルされた' };
    case AppointmentCancellationReason.Prov:
      return { canceledBy: '薬局', detail: 'お薬の在庫がなかった' };
    case AppointmentCancellationReason.Other:
      return { canceledBy: '薬局', detail: 'その他' };
    default:
      return { canceledBy: '不明', detail: '不明' };
  }
};
