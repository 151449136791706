import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert } from '~/components/blocks';

const Root = styled(Alert)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    padding: theme.space.m,
    '& ul, li': {
      listStyleType: 'initial',
      listStylePosition: 'inside',
    },
  }),
);

const Link = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.default,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

export const Warning = () => {
  const theme = useTheme();

  return (
    <Root status="warning" marginY={theme.space.l}>
      <ul>
        <li>内容に間違いないか確認のうえ、送信してください</li>
        <li>宣伝や広告内容を含むメッセージはご遠慮ください</li>
        <li>
          詳細は
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://intercom.help/pharms/ja/articles/5337667"
          >
            メッセージガイドライン
          </Link>
          をご確認ください
        </li>
      </ul>
    </Root>
  );
};
