import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';
import { ColorPalette, MonoColor } from '~/styles/theme';
import { Size } from '~/styles/types';

type Props = {
  error: string;
  fontSize?: Size;
  color?: keyof typeof ColorPalette | keyof typeof MonoColor;
};

export const FieldError = React.memo((props: Props) => {
  const { error, fontSize = 'm', color = 'pink' } = props;

  return (
    <Flex alignItems="center">
      <Icon size="l" icon="attention" />
      <Text whiteSpace="break-spaces" color={color} size={fontSize}>
        {error}
      </Text>
    </Flex>
  );
});

FieldError.displayName = 'FieldError';
