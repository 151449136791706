import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { ERROR_BASE_DATE } from '~/constants/organization_notification';
import {
  useGetUnreadFollowupMessageNotificationsLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { followupMessageErrorState } from '~/state/layouts/SharedAppShell/atoms';
import { FollowupMessageErrorNotification } from '~/state/layouts/SharedAppShell/types';

export const useSubscriptionFollowupMessage = () => {
  const [{ notifications, loaded }, setState] = useRecoilState(followupMessageErrorState);
  const [event, clear] = useSubscriptions('OrganizationNotification', ['created', 'updated']);
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetUnreadFollowupMessageNotificationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const organizationNotifications = _data
        ? getMe(_data)?.organization.unreadOrganizationNotifications.nodes || []
        : [];

      const notifications = organizationNotifications
        .filter((n) => !!n.medicationFollowupMessage)
        .map((n) => {
          return {
            followupMessage: n.medicationFollowupMessage || '',
            organizationNotificationId: n.id,
            createdAt: n.createdAt,
          } as FollowupMessageErrorNotification;
        });

      setState((_state) => ({
        ..._state,
        notifications,
        loaded: true,
      }));
    },
  });

  const handleClose = useCallback(
    async (followupMessageId: string) => {
      const newNotifications = notifications.filter(
        (notification) => notification.followupMessage.id !== followupMessageId,
      );
      setState((_state) => ({ ..._state, notifications: newNotifications }));

      const notificationId = notifications.find(
        (n) => n.followupMessage.id === followupMessageId,
      )?.organizationNotificationId;

      if (notificationId) {
        await markNotification({
          variables: {
            input: {
              organizationNotificationId: notificationId,
            },
          },
        });
      }
    },
    [markNotification, notifications, setState],
  );

  useEffect(() => {
    if (event) {
      getNotifications({ variables: { from: ERROR_BASE_DATE } });
      clear();
    }
  }, [clear, event, getNotifications]);

  useEffect(() => {
    if (!loaded) {
      getNotifications({ variables: { from: ERROR_BASE_DATE } });
    }
  }, [getNotifications, loaded]);

  return {
    handleClose,
  };
};
