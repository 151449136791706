import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Button, Flex } from '~/components/blocks';
import { CancelWaitingForChargePane } from '~/components/partials/CancelWaitingForChargePane';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';
import { chargeModalState } from '~/state/partials/charge_modal/atoms';

import { useCancelAppointment } from './use-cancel-appointment';

type Props = {
  appointmentId: string;
  draftAppointmentId: string | undefined;
  telemedicine: boolean;
};

export const WaitingForChargeButtons = (props: Props) => {
  const { appointmentId, draftAppointmentId, telemedicine } = props;
  const { canceling, handleCancel } = useCancelAppointment(appointmentId);

  // 会計モーダルで確定後
  // - 未会計 -> 会計済(配送待ち) （telemedicine: true）
  // - 指導待ち -> 未会計 -> 完了 （telemedicine: false）
  const openChargeModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chargeModalState, {
          appointmentId,
          isOpen: true,
        });
      },
    [appointmentId],
  );

  // キャンセル
  const handleClickCancel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelPane, {
          isOpen: true,
        });
      },
    [],
  );

  return (
    <>
      <Flex flexDirection="column" justifyContent="center">
        <Button use="secondary" wide="fill" size="l" onClick={openChargeModal}>
          会計
        </Button>
        {/*対面のみキャンセル可*/}
        {!telemedicine && (
          <Button use="white" onClick={handleClickCancel}>
            キャンセル
          </Button>
        )}
      </Flex>
      <CancelWaitingForChargePane
        align="center"
        canceling={canceling}
        onCancel={handleCancel}
        draftAppointmentId={draftAppointmentId}
      />
    </>
  );
};
