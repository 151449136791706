import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert, Box, Flex, Icon, Text } from '~/components/blocks';
import { PfDispensingRequestSendMethod } from '~/graphql';

type Props = {
  draftAppointmentId: string | undefined;
  prescriptionSendMethod?: PfDispensingRequestSendMethod | undefined;
};

const Guidance = styled(Box)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    backgroundColor: theme.colors.background.default,
    padding: `${theme.space.m} ${theme.space.l}`,
    margin: `${theme.space.m} 0`,
    borderRadius: theme.radii.default,
    '& ul, li': {
      listStyleType: 'initial',
      listStylePosition: 'inside',
      marginLeft: '16px',
      textIndent: '-16px',
    },
  }),
);

export const CancelAlert = React.memo(({ draftAppointmentId, prescriptionSendMethod }: Props) => {
  const theme = useTheme();

  return (
    <Alert status="warning" mt={theme.space.m} withIcon={false}>
      <Flex alignItems="center" mt={theme.space.s}>
        <Icon size="l" icon="alert" color="yellow" />
        <Text ml={theme.space.s} size="s" fontWeight={theme.fontWeights.bold}>
          必ずご確認ください
        </Text>
      </Flex>
      <Guidance>
        <Text size="s" fontWeight="bold">
          キャンセルすると
        </Text>
        <ul>
          <li>患者へ通知が送信されます</li>
          {draftAppointmentId && <li>医療機関から処方箋原本が送付されません</li>}
        </ul>
      </Guidance>
      <Guidance>
        <Text size="s" fontWeight="bold">
          キャンセルする前に
        </Text>
        <ul>
          <li>患者に理由を伝え、他薬局での薬の受け取り方法をご案内してください</li>
          {prescriptionSendMethod === 'FAX' && (
            <li>医療機関に、薬局が変更されることをご案内してください</li>
          )}
        </ul>
      </Guidance>
    </Alert>
  );
});

CancelAlert.displayName = 'CancelAlert';
