import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

const Root = styled('div')(({ theme }) =>
  css({
    lineHeight: theme.fontSizes.xxs,
    fontSize: theme.fontSizes.xxs,
    color: theme.colors.colorPallete.grey,
    textAlign: 'center',
    marginLeft: theme.space.m,
    marginRight: theme.space.m,
  }),
);

const Link = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.default,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

/**
 * reCaputureのロゴを非表示にする場合は以下のことをしないといけない
 * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
 */
export const Policy = () => {
  return (
    <Root>
      This site is protected by reCAPTCHA and the Google&nbsp;
      <Link href="https://policies.google.com/privacy">Privacy Policy</Link> and&nbsp;
      <Link href="https://policies.google.com/terms">Terms of Service</Link> apply.
    </Root>
  );
};
