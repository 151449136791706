import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, Icon, List, Text } from '~/components/blocks';
import { NotificationListItem } from '~/components/partials/NotificationListItem';

import { Notification } from './types';

type Props = {
  notification: Notification;
};

const CustomList = styled(List.Item)(({ theme }) =>
  css({
    opacity: 0.5,
    padding: 0,
    borderTop: theme.borders.default,
    '&:first-of-type': {
      borderTop: '0',
    },
    '&:hover': {
      background: 'none',
    },
  }),
);

export const ExpiredMedicineNoteNotification = React.memo((props: Props) => {
  const { notification } = props;
  const theme = useTheme();

  return (
    <CustomList>
      <NotificationListItem isMarked={false}>
        <Flex flexDirection="column">
          <Text fontWeight="bold" size="s">
            {`${notification.patientProfile.name}様からお薬手帳が開示されました`}
            <br />
            有効期限：{notification.expiresAt}
          </Text>
          <Text size="xs" color="grey01" marginTop={theme.space.xs}>
            {notification.createdAt}
          </Text>
        </Flex>
        <Flex marginLeft="auto" alignItems="center">
          <Icon icon="alert" size="m" />
          <Text size="xs" fontWeight="bold">
            期限切れ
          </Text>
        </Flex>
      </NotificationListItem>
    </CustomList>
  );
});

ExpiredMedicineNoteNotification.displayName = 'ExpiredMedicineNoteNotification';
