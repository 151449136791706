import { useTheme } from '@emotion/react';
import React, { PropsWithChildren } from 'react';

import { Alert, Box, Text } from '~/components/blocks';
import { ReceptionPaneDraftAppointmentFragment } from '~/graphql';

type Props = {
  draftAppointment: ReceptionPaneDraftAppointmentFragment;
  medicalInstitutionName: string | undefined;
};

const Info = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  return (
    <Box paddingY={theme.space.m}>
      <Alert status="info">{children}</Alert>
    </Box>
  );
};

export const StaffActionInfo = ({ draftAppointment, medicalInstitutionName }: Props) => {
  const { status, pfDispensingRequest } = draftAppointment;

  if (status === 'available') {
    return (
      <Info>
        <Text>
          {medicalInstitutionName ? medicalInstitutionName : '医療機関'}
          からの処方箋到着待ちです。FAXで共有またはアップロードされるまでお待ちください
        </Text>
      </Info>
    );
  }

  if (status === 'wait_for_booking' && pfDispensingRequest?.sendMethod === 'FAX') {
    return (
      <Info>
        <Text>
          処方箋をFAX等で受理しました。
          <br />
          患者から服薬指導の希望日時が指定されるまでお待ちください
        </Text>
      </Info>
    );
  }

  if (status === 'wait_for_booking' && pfDispensingRequest?.sendMethod === 'UPLOAD') {
    return (
      <Info>
        <Text>
          処方箋がアップロードされました。
          <br />
          患者から服薬指導の希望日時が指定されるまでお待ちください
        </Text>
      </Info>
    );
  }

  return null;
};
