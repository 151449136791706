import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Loader } from '~/components/blocks';
import { FirebaseCustormTokenFragment, useGetFirebaseCustomTokenLazyQuery } from '~/graphql';
import { clearJWT } from '~/localstrage/jwt';
import { logoutState } from '~/state/layouts/SharedAppShell/atoms';

import { FirebaseContext } from './context';
import { app, firebase } from './firebase';

type Props = { children?: React.ReactNode };

export const FirebaseProvider: React.FC<Props> = (props) => {
  const auth = app.auth();
  const db = app.database();

  const { isLogout } = useRecoilValue(logoutState);
  const [hasSingedIn, setHasSingedIn] = useState<boolean>(!!auth.currentUser);
  const signIn = useCallback(
    async (_customeToken: string) => {
      await auth.signInWithCustomToken(_customeToken).catch(() => {
        // firebaseのサインインに失敗した場合は、再ログインを促す
        clearJWT();
        location.href = '/login';
      });
    },
    [auth],
  );
  const [getFirebaseCustomToken, { called }] = useGetFirebaseCustomTokenLazyQuery({
    onCompleted: async (data) => {
      const _customeToken = (data?.me as FirebaseCustormTokenFragment)?.customToken || null;

      if (_customeToken) {
        await auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
        await signIn(_customeToken);
      } else {
        clearJWT();
        location.href = '/login';
      }
    },
  });

  useEffect(() => {
    if (!called && !hasSingedIn) {
      getFirebaseCustomToken();
    }
  }, [called, getFirebaseCustomToken, hasSingedIn]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setHasSingedIn(true);
      } else {
        if (!isLogout) {
          setHasSingedIn(false);
        }
      }
    });
  }, [auth, called, getFirebaseCustomToken, isLogout]);

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        auth,
        db,
      }}
    >
      {hasSingedIn ? props.children : <Loader open />}
    </FirebaseContext.Provider>
  );
};
