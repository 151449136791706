import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Flex, Icon, List, Text } from '~/components/blocks';
import { DEFAULT_WINDOW } from '~/components/partials';
import { NotificationListItem } from '~/components/partials/NotificationListItem';
import {
  GetUnreadTemporaryPermissionsDocument,
  useMarkTemporaryPermissionMutation,
} from '~/graphql';
import { openWithNoOpener } from '~/utils/window';

import { Notification } from './types';

type Props = {
  notification: Notification;
};

const CustomList = styled(List.Item)(({ theme }) =>
  css({
    padding: 0,
    transitionDuration: theme.transitions.default,
    borderTop: theme.borders.default,
    '&:first-of-type': {
      borderTop: '0',
    },
    '&:hover': {
      background: theme.colors.background.bg,
      cursor: 'pointer',
    },
  }),
);

export const MedicineNoteNotification = React.memo((props: Props) => {
  const { notification } = props;
  const theme = useTheme();

  const [markTemporaryPermission] = useMarkTemporaryPermissionMutation({
    refetchQueries: [{ query: GetUnreadTemporaryPermissionsDocument }],
  });

  const handleClickButton = useCallback(() => {
    if (notification.type === 'OnetimeApiCache') {
      openWithNoOpener(`/medicinenote/onetime_codes/${notification.onetimeCode}`, {
        target: notification.onetimeCode,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });
    }
    if (notification.type === 'TemporaryPermission') {
      openWithNoOpener(`/medicinenote/patients/${notification.patientId}`, {
        target: notification.patientId,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });

      if (!notification.isRead) {
        markTemporaryPermission({
          variables: {
            input: {
              hiccupTemporaryPermissionId: notification.temporaryPermissionId,
            },
          },
        });
      }
    }
  }, [notification, markTemporaryPermission]);

  return (
    <CustomList>
      <NotificationListItem
        onClick={handleClickButton}
        isMarked={notification.type === 'TemporaryPermission' && !notification.isRead}
      >
        <Flex flexDirection="column">
          <Text fontWeight="bold" size="s">
            {`${notification.patientProfile.name}様からお薬手帳が開示されました`}
            <br />
            有効期限：{notification.expiresAt}
          </Text>
          <Text size="xs" color="grey01" marginTop={theme.space.xs}>
            {notification.createdAt}
          </Text>
        </Flex>
        <Icon icon="forward-line" size="m" marginLeft="auto" />
      </NotificationListItem>
    </CustomList>
  );
});

MedicineNoteNotification.displayName = 'MedicineNoteNotification';
