import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { BASE_DATE } from '~/constants/organization_notification';
import {
  useGetUncompletedAppointmentsNotificationsCountQuery,
  useGetUnreadMedicationFollowupQuestionnaireSheetsQuery,
  useGetUnreadTemporaryPermissionsQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { notificationState } from '~/state/layouts/SharedAppShell/atoms';

export const useSubscriptionNotifications = () => {
  const setState = useSetRecoilState(notificationState);

  const [organizationNotificationEvent] = useSubscriptions('OrganizationNotification', [
    'created',
    'updated',
  ]);
  const [medicationFollowupEvent] = useSubscriptions('MedicationFollowupQuestionnaireSheet', [
    'created',
    'updated',
  ]);
  const [medicineNoteTemporaryPermissionEvent] = useSubscriptions('HiccupTemporaryPermission', [
    'created',
  ]);

  const { loading: isFollowupLoading, refetch: refetchFollowup } =
    useGetUnreadMedicationFollowupQuestionnaireSheetsQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: { from: BASE_DATE },
      onCompleted: (_result) => {
        const count =
          getMe(_result)?.organization.medicationFollowupQuestionnaireSheets.nodesCount || 0;
        setState((_state) => ({ ..._state, followupCount: count }));
      },
    });

  const { loading: isNotificationLoading, refetch: refetchNotification } =
    useGetUncompletedAppointmentsNotificationsCountQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: { from: BASE_DATE },
      onCompleted: (_result) => {
        setState((_state) => ({
          ..._state,
          receptionCount: getMe(_result)?.organization.organizationNotifications.nodesCount || 0,
        }));
      },
    });

  const { loading: isMedicineNoteLoading, refetch: refetchMedicineNote } =
    useGetUnreadTemporaryPermissionsQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (_result) => {
        const count = getMe(_result)?.organization.hiccupTemporaryPermissions.nodesCount || 0;
        setState((_state) => ({ ..._state, medicineNoteCount: count }));
      },
    });

  useEffect(() => {
    if (
      medicationFollowupEvent?.actionName === 'created' ||
      medicationFollowupEvent?.actionName === 'updated'
    ) {
      refetchFollowup();
    }
  }, [medicationFollowupEvent, refetchFollowup]);

  useEffect(() => {
    if (
      organizationNotificationEvent?.actionName === 'created' ||
      organizationNotificationEvent?.actionName === 'updated'
    ) {
      refetchNotification();
    }
  }, [organizationNotificationEvent, refetchNotification]);

  useEffect(() => {
    if (medicineNoteTemporaryPermissionEvent?.actionName === 'created') {
      refetchMedicineNote();
    }
  }, [medicineNoteTemporaryPermissionEvent, refetchMedicineNote]);

  useEffect(() => {
    refetchFollowup();
    refetchNotification();
    refetchMedicineNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: isFollowupLoading || isNotificationLoading || isMedicineNoteLoading,
  };
};
