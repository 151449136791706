import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { variant } from 'styled-system';

import { Flex, Text } from '~/components/blocks';

type Props = {
  label: string;
  count: number;
};

const Root = styled(Flex)(() =>
  css({
    alignItems: 'center',
    justifyContent: 'center',
    width: '103px',
  }),
);

const Badge = styled('div', { shouldForwardProp })<{ color?: 'grey' }>(
  ({ theme }) =>
    css({
      backgroundColor: theme.colors.colorPallete.pink,
      borderRadius: '50%',
      color: theme.colors.text.white,
      fontSize: theme.fontSizes.xs,
      width: `calc(${theme.space.l} + ${theme.space.xs})`,
      height: `calc(${theme.space.l} + ${theme.space.xs})`,
      lineHeight: `calc(${theme.space.l} + ${theme.space.xs})`,
      textAlign: 'center',
      fontWeight: 'bold',
    }),
  ({ theme }) =>
    variant({
      prop: 'color',
      variants: {
        grey: css({
          backgroundColor: theme.colors.colorPallete.grey03,
        }),
      },
    }),
);

export const TabLabel = (props: Props) => {
  const theme = useTheme();
  return (
    <Root>
      <Text mr={theme.space.s}>{props.label}</Text>
      {props.count > 0 ? <Badge>{props.count}</Badge> : <Badge color="grey">0</Badge>}
    </Root>
  );
};
