import React, { ComponentProps } from 'react';

import { Chip } from '~/components/blocks';
import { ReceptionStatus } from '~/components/partials/ReceptionStatus';
import { AppointmentStatus, ReceptionUberDeliveryFragment, UberDeliveryStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  uberDelivery?: ReceptionUberDeliveryFragment | null | undefined;
  status: AppointmentStatus;
  isSameDayDelivery?: boolean;
};

export const UberDeliveryAppointmentStatusLabel = (props: Props) => {
  const { status, uberDelivery, isSameDayDelivery } = props;

  const uberDeliveryStatus = uberDelivery?.status;

  if (!isSameDayDelivery) {
    return null;
  }

  if (!uberDelivery && status === AppointmentStatus.WaitingForCharge) {
    return (
      <ReceptionStatus
        label="集荷待ち"
        chipColor="cyan"
        labelColor="white"
        detailedStatus={{ label: '未依頼', color: 'pink', iconName: 'uber' }}
      />
    );
  }

  if (uberDeliveryStatus === UberDeliveryStatus.UberSupportCanceled) {
    return (
      <ReceptionStatus
        label="配達待ち"
        chipColor="grey"
        labelColor="white"
        detailedStatus={{ label: 'キャンセル', color: 'pink', iconName: 'uber' }}
      />
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Unfulfilled &&
    status === AppointmentStatus.WaitingForCharge
  ) {
    return (
      <ReceptionStatus
        label="集荷待ち"
        chipColor="cyan"
        labelColor="white"
        detailedStatus={{ label: '未依頼', color: 'pink', iconName: 'uber' }}
      />
    );
  }

  if (uberDeliveryStatus === UberDeliveryStatus.Dropoff && status === AppointmentStatus.Charged) {
    return <ReceptionStatus label="配達待ち" chipColor="grey" labelColor="white" />;
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Canceled &&
    (status === AppointmentStatus.WaitingForCharge || status === AppointmentStatus.Charged)
  ) {
    return (
      <ReceptionStatus
        label="配達待ち"
        chipColor="grey"
        labelColor="white"
        detailedStatus={{ label: 'キャンセル', color: 'pink', iconName: 'uber' }}
      />
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Pickup &&
    (status === AppointmentStatus.WaitingForCharge || status === AppointmentStatus.Charged)
  ) {
    return (
      <ReceptionStatus
        label="集荷待ち"
        chipColor="cyan"
        labelColor="white"
        detailedStatus={{ label: '配達員待ち', color: 'green', iconName: 'uber' }}
      />
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Pending &&
    status === AppointmentStatus.WaitingForCharge
  ) {
    return <ReceptionStatus label="集荷待ち" chipColor="cyan" labelColor="white" />;
  }

  return null;
};
