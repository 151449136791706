import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { Flex, Icon, Text } from '~/components/blocks';
import { NotificationListItem } from '~/components/partials/NotificationListItem';
import { AnsweredQuestionnaireSheetFragment, DirectVisitor } from '~/graphql';
import { notificationPanelState } from '~/state/layouts/SharedAppShell/atoms';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';
import { Label } from '~/utils/label';

type Props = {
  sheet: AnsweredQuestionnaireSheetFragment;
};

export const Notification = React.memo((props: Props) => {
  const { sheet } = props;
  const theme = useTheme();

  const patient = useMemo(() => {
    const messageDeliverGuestPatient =
      sheet.medicationFollowupMessage.messageDeliverResult?.guestPatient?.__typename ===
      'DirectVisitor'
        ? (sheet.medicationFollowupMessage.messageDeliverResult.guestPatient as DirectVisitor)
        : null;
    return (
      sheet.medicationFollowupMessage.appointment?.patient ||
      sheet.medicationFollowupMessage.appointment?.webVisitor ||
      sheet.medicationFollowupMessage.visit?.directVisitor ||
      messageDeliverGuestPatient
    );
  }, [
    sheet.medicationFollowupMessage.appointment?.patient,
    sheet.medicationFollowupMessage.appointment?.webVisitor,
    sheet.medicationFollowupMessage.messageDeliverResult?.guestPatient,
    sheet.medicationFollowupMessage.visit?.directVisitor,
  ]);
  const patientName = patient ? `${patient.familyName}${patient.givenName}` : 'ー';

  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        if (!patient) return;

        set(patientDetailDialogState, (_state) => ({
          ..._state,
          isOpen: true,
          patientId: patient.id,
          type: 'message' as TabType,
        }));

        // すぐ閉じるとReactのWarningが出るため、一定時間経過後に閉じる
        setTimeout(() => {
          reset(notificationPanelState);
        }, 200);
      },
    [patient],
  );

  return (
    <NotificationListItem onClick={handleClick} isMarked={!sheet.medicationFollowupMessage.isRead}>
      <Flex flexDirection="column" marginRight={theme.space.m}>
        <Text fontWeight="bold" size="s">
          {`${patientName}様から質問票への回答がありました。回答内容をご確認ください`}
        </Text>
        <Text size="xs" color="grey01" marginTop={theme.space.xs}>
          {`${Label.YYYYMMDDja(sheet.createdAt)} ${Label.HHMM(sheet.createdAt)}`}
        </Text>
      </Flex>
      <Icon icon="forward-line" size="m" marginLeft="auto" />
    </NotificationListItem>
  );
});

Notification.displayName = 'Notification';
