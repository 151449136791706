import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { compose, variant } from 'styled-system';

import { Size } from '~/styles/types';

type Props = {
  size: Size;
  text: string;
};

const Span = styled('span')<{ size: Size }>(({ theme }) =>
  compose(
    variant({
      prop: 'size',
      variants: {
        xxs: css({
          fontSize: theme.fontSizes.xxs,
        }),
        xs: css({
          fontSize: theme.fontSizes.xs,
        }),
        s: css({
          fontSize: theme.fontSizes.s,
        }),
        m: css({
          fontSize: theme.fontSizes.m,
        }),
        l: css({
          fontSize: theme.fontSizes.l,
        }),
        xl: css({
          fontSize: theme.fontSizes.xl,
        }),
        xxl: css({
          fontSize: theme.fontSizes.xxl,
        }),
      },
    }),
  ),
);

export const TextSpan = (props: Props) => {
  return <Span size={props.size}>{props.text}</Span>;
};
