import React from 'react';
import { useRecoilValue } from 'recoil';

import { faximoSendFaxErrorState } from '~/state/layouts/SharedAppShell/atoms';

import { SendFaxErrorNotification } from './SendFaxErrorNotification';
import { useSubscriptionFaximoSendFax } from './use-subscription-faximo_send_fax';

export const SendFaxNotifications = () => {
  const { notifications } = useRecoilValue(faximoSendFaxErrorState);
  const { handleClose } = useSubscriptionFaximoSendFax();

  return (
    <>
      {notifications.map((notification) => (
        <SendFaxErrorNotification
          key={notification.organizationNotificationId}
          faximoSendFaxId={notification.faximoSendFaxId}
          notifiedAt={notification.createdAt}
          onClose={handleClose}
        />
      ))}
    </>
  );
};
