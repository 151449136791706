import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { variant } from 'styled-system';

import { List } from '~/components/blocks';

type Props = {
  children: React.ReactNode;
  isMarked: boolean;
  onClick?: ((e: React.MouseEvent<HTMLLIElement, MouseEvent>) => Promise<void>) | (() => void);
};

const Item = styled('li', { shouldForwardProp })<{
  color: 'pink' | 'white';
}>(
  ({ theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      '&::before': {
        content: '"●"',
        fontSize: '16px',
        marginRight: theme.space.m,
      },
    }),
  ({ theme }) =>
    variant({
      prop: 'color',
      variants: {
        pink: css({
          '&::before': {
            color: theme.colors.colorPallete.pink,
          },
        }),
        white: css({
          '&::before': {
            color: theme.colors.colorPallete.white,
            opacity: 0,
          },
        }),
      },
    }),
);

export const NotificationListItem: React.FC<Props> = ({ children, isMarked, onClick }) => {
  return (
    <List.Item onClick={onClick}>
      <Item color={isMarked ? 'pink' : 'white'}>{children}</Item>
    </List.Item>
  );
};
