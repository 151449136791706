import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';

const BoldSpan = styled('span')(() =>
  css({
    fontWeight: 'bold',
  }),
);

const Link = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

export const UberDeliveryFreePromotionText = React.memo(() => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" marginTop={theme.space.m}>
      <Icon color="blue" icon="info" size="m" marginRight={theme.space.s} />
      <Text size="s">
        キャンペーン適用対象につき、今回の当日配達利用料は<BoldSpan>無料</BoldSpan>
        となります（
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://intercom.help/pharms/ja/articles/9765203"
        >
          キャンペーン詳細
        </Link>
        <Icon
          icon="blank"
          size="m"
          marginLeft={theme.space.s}
          marginRight={theme.space.s}
          color="linkBlue"
        />
        ）
      </Text>
    </Flex>
  );
});

UberDeliveryFreePromotionText.displayName = 'UberDeliveryFreePromotionText';
