import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { ERROR_BASE_DATE } from '~/constants/organization_notification';
import {
  useGetUnreadSendFaxErrorNotificationsLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { faximoSendFaxErrorState } from '~/state/layouts/SharedAppShell/atoms';
import { FaximoSendFaxErrorNotification } from '~/state/layouts/SharedAppShell/types';

export const useSubscriptionFaximoSendFax = () => {
  const [{ notifications, loaded }, setState] = useRecoilState(faximoSendFaxErrorState);
  const [event, clear] = useSubscriptions('OrganizationNotification', ['created', 'updated']);
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetUnreadSendFaxErrorNotificationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const organizationNotifications = data
        ? getMe(data)?.organization.unreadOrganizationNotifications.nodes || []
        : [];
      const notifications = organizationNotifications
        .filter((n) => !!n.faximoSendFax)
        .map((n) => {
          return {
            faximoSendFaxId: n.faximoSendFax?.id || '',
            organizationNotificationId: n.id,
            createdAt: n.createdAt,
          } as FaximoSendFaxErrorNotification;
        });

      setState((_state) => ({
        ..._state,
        notifications,
        loaded: true,
      }));
    },
  });

  const handleClose = useCallback(
    async (faximoSendFaxId: string) => {
      const newNotifications = notifications.filter(
        (notification) => notification.faximoSendFaxId !== faximoSendFaxId,
      );
      setState((_state) => ({ ..._state, notifications: newNotifications }));

      const notificationId = notifications.find(
        (n) => n.faximoSendFaxId === faximoSendFaxId,
      )?.organizationNotificationId;

      if (notificationId) {
        await markNotification({
          variables: {
            input: {
              organizationNotificationId: notificationId,
            },
          },
        });
      }
    },
    [markNotification, notifications, setState],
  );

  useEffect(() => {
    if (event) {
      getNotifications({ variables: { from: ERROR_BASE_DATE } });
      clear();
    }
  }, [clear, event, getNotifications]);

  useEffect(() => {
    // ログイン時にエラー通知を取得する
    if (!loaded) {
      getNotifications({ variables: { from: ERROR_BASE_DATE } });
    }
  }, [getNotifications, loaded]);

  return {
    handleClose,
  };
};
