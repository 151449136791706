import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { format } from 'date-fns';
import React from 'react';

import { Flex, Snackbar, Text } from '~/components/blocks';

const DURATION = 1000;

type Props = {
  open: boolean;
  children: React.ReactNode;
  isNotification: boolean;
  notifiedAt: Date | string;
  onClick: () => void;
  onClose: () => void;
  onExited: () => void;
};

const Notification = styled('div')<{ disabled: boolean }>(({ disabled }) =>
  css({
    display: 'flex',
    cursor: disabled ? 'none' : 'pointer',
  }),
);

const Action = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    padding: `22px calc(${theme.space.m} + ${theme.space.s})`,
    width: '280px',
    position: 'relative',
  }),
);

const Close = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    borderLeft: `1px ${theme.colors.colorPallete.grey05} solid`,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: `calc(${theme.space.m} + ${theme.space.s})`,
  }),
);

export const ActionSnackbar: React.FC<Props> = (props) => {
  const notifiedAt = format(props.notifiedAt, 'yyyy/MM/dd HH:mm');

  return (
    <Snackbar open={props.open} timeout={DURATION} onExited={props.onExited}>
      <Notification disabled={props.isNotification}>
        <Action onClick={props.onClick}>
          {props.children}
          <Flex position="absolute" bottom="2px" right="10px">
            <Text size="xxs" color="grey03">
              {notifiedAt}
            </Text>
          </Flex>
        </Action>
        <Close onClick={props.onClose}>閉じる</Close>
      </Notification>
    </Snackbar>
  );
};
