import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, EntryList, Flex, Grid, Icon, Loader, Tag, Text } from '~/components/blocks';
import {
  AppointmentStatusLabel,
  DispensingRequestCollapse,
  InsuranceCardCollapse,
  MemoField,
  ReceptionTypeIcon,
} from '~/components/partials';
import { ImmediateInfo } from '~/components/partials/ImmediateInfo';
import { ReceiveOptionBlock } from '~/components/partials/ReceiveOptionBlock';
import { TabMap } from '~/constants/tab';
import { AppointmentDeliveryMethod } from '~/graphql';
import { filePageInfoState, tabState } from '~/state/partials/patient_karte/atoms';
import { translateReason } from '~/utils/appointments';
import { Label } from '~/utils/label';

import { MedicineNoteButton } from './MedicineNoteButton';
import { MedixsReceptionId } from './MedixsReceptionId';
import { ReceiveOptionSubContent } from './ReceiveOptionSubContent';
import { SameDayDeliveryInfo } from './SameDayDeliveryInfo';
import { useFetchAppointment } from './use-fetch-appointment';
import { useUpdateDescription } from './use-update-description';

type Props = {
  appointmentId: string;
};

const CustomTag = styled(Tag)(({ theme }) =>
  css({
    background: theme.colors.colorPallete.grey04,
    border: 'none',
  }),
);

export const Appointment = React.memo((props: Props) => {
  const theme = useTheme();

  const { loading, appointment } = useFetchAppointment(props.appointmentId);
  const { updating, latestMemo, handleChangeMemo } = useUpdateDescription(props.appointmentId);

  const isActivePatient = !!appointment?.patient?.active;
  const patientId = appointment?.patient?.id;

  const cancellationReason = appointment?.cancellationReason
    ? translateReason(
        appointment.cancellationReason,
        appointment.status,
        appointment.telemedicine ? undefined : !!appointment.waitingForChargeAt,
      )
    : null;

  const handleClickCertificate = useRecoilCallback(
    ({ set }) =>
      () => {
        if (patientId) {
          set(tabState, { type: TabMap.file });
          set(filePageInfoState, (_state) => ({
            ..._state,
            patientId,
            page: 1,
            filter: { sent: false, received: true },
          }));
        }
      },
    [patientId],
  );

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      {loading && <Loader open inside transparent appearance="white" />}
      {appointment && (
        <>
          <MedixsReceptionId
            appointmentId={appointment.id}
            appointmentStatus={appointment.status}
            medixsRecpetion={appointment.medixsReception}
          />
          <Grid
            gridTemplateColumns="min-content auto"
            gridTemplateRows="50px min-content"
            paddingTop={theme.space.m}
            alignItems="center"
          >
            <Flex justifyContent="center">
              <ReceptionTypeIcon telemedicine={appointment.telemedicine} size="xxxl" mr="xxs" />
            </Flex>
            <Flex justifyItems="start" alignItems="center">
              {appointment.telemedicine ? (
                <Text fontWeight="bold" size="m" paddingLeft={theme.space.s}>
                  オンライン服薬指導
                </Text>
              ) : (
                <Text fontWeight="bold" size="m" paddingLeft={theme.space.s}>
                  対面服薬指導
                </Text>
              )}
              <Box marginLeft="auto">
                {appointment && (
                  <AppointmentStatusLabel
                    status={appointment.status}
                    uberDelivery={appointment.uberDelivery}
                    isSameDayDelivery={
                      appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
                    }
                  />
                )}
              </Box>
            </Flex>
            {appointment.start && appointment.end && (
              <>
                <Flex justifyContent="center">
                  <Icon icon="time" size="xl" />
                </Flex>
                <Flex justifyItems="start">
                  <Text fontWeight="bold" size="m" marginLeft={theme.space.s}>
                    {Label.YYYYMMDDja(appointment.start)}
                  </Text>
                  <Text fontWeight="bold" size="m" marginLeft={theme.space.s}>
                    {Label.HHMM(appointment.start)}-{Label.HHMM(appointment.end)}
                  </Text>
                </Flex>
              </>
            )}
          </Grid>
          {appointment.immediate && (
            <ImmediateInfo
              text={
                appointment.telemedicine ? '準備ができ次第開始希望' : '準備ができ次第受け取り希望'
              }
            />
          )}
          <ReceiveOptionBlock appointment={appointment} isOnline={false}>
            {appointment.uberDelivery?.id &&
              appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery && (
                <ReceiveOptionSubContent uberDelivery={appointment.uberDelivery} />
              )}
          </ReceiveOptionBlock>
          {appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery && (
            <SameDayDeliveryInfo
              appointmentStatus={appointment.status}
              uberDelivery={appointment.uberDelivery}
            />
          )}
          {cancellationReason && (
            <Alert status="warning" marginY={theme.space.m} withIcon={false}>
              <Flex flexDirection="column">
                <Text size="s" fontWeight={theme.fontWeights.bold}>
                  {cancellationReason.canceledBy}
                  からキャンセル
                </Text>
                {cancellationReason.canceledBy === '薬局' && (
                  <Text size="s">{cancellationReason.detail}</Text>
                )}
              </Flex>
            </Alert>
          )}
          <DispensingRequestCollapse printable appointmentId={props.appointmentId} />
          <InsuranceCardCollapse
            patientId={patientId}
            isActivePatient={isActivePatient}
            onClickCertificate={handleClickCertificate}
            qualificationConfirmationMethod={appointment.qualificationConfirmationMethod!}
          />
          {appointment && (
            <EntryList mt={theme.space.l}>
              <MedicineNoteButton
                isActivePatient={isActivePatient}
                permission={appointment.hiccupAppointmentPermission || undefined}
                patientId={patientId || ''}
                status={appointment.status}
              />
            </EntryList>
          )}
          <EntryList mt={theme.space.l}>
            <EntryList.Head>申し込み日時</EntryList.Head>
            <EntryList.Body>
              <Flex>
                <Box>
                  <Text size="m">
                    {appointment?.createdAt
                      ? `${Label.YYYYMMDDja(appointment.createdAt)}
        ${Label.HHMM(appointment.createdAt)}`
                      : null}
                  </Text>
                </Box>
                <Box>
                  {appointment.telemedicine ? (
                    <Text size="m" ml={theme.space.s}>
                      （オンライン服薬指導）
                    </Text>
                  ) : (
                    <Text size="m" ml={theme.space.s}>
                      （処方箋ネット受付）
                    </Text>
                  )}
                </Box>
              </Flex>
            </EntryList.Body>
          </EntryList>
          <EntryList mt={theme.space.l}>
            <EntryList.Head>メモ</EntryList.Head>
            <EntryList.Body>
              <CustomTag>
                <Flex alignItems="center">
                  <Icon icon="smartphone" size="l" />
                  <Text size="m">CLINICS会員</Text>
                </Flex>
              </CustomTag>
              <MemoField
                disabled={updating}
                value={latestMemo.mutationCalled ? latestMemo.memo : appointment?.description || ''}
                onChange={handleChangeMemo}
              />
            </EntryList.Body>
          </EntryList>
        </>
      )}
    </Box>
  );
});

Appointment.displayName = 'AppointmentOnReceptionPane';
