import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { Icon, Text } from '~/components/blocks';
import { sentFaxDeatilDrawerState } from '~/state/fax/atoms';

import { ActionSnackbar } from '../ActionSnackbar';

type Props = {
  faximoSendFaxId: string;
  notifiedAt: string;
  onClose: (faximoSendFaxId: string) => void;
};

export const SendFaxErrorNotification = React.memo((props: Props) => {
  const { faximoSendFaxId, notifiedAt, onClose } = props;
  const theme = useTheme();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openSentFaxDetailDrawer = useRecoilCallback(
    ({ set }) =>
      () => {
        if (router.pathname !== '/fax') {
          router.push('/fax');
          // unmountが完了する前にページ遷移が完了すると通知が再表示されてしまう
          // 通知を管理してる大元のリストから削除し、通知が再表示されないようにする
          onClose(faximoSendFaxId);
        } else {
          handleClose();
        }
        if (faximoSendFaxId) {
          set(sentFaxDeatilDrawerState, {
            isOpen: true,
            faximoSendFaxId: faximoSendFaxId,
          });
        }
      },
    [faximoSendFaxId, handleClose, onClose, router],
  );

  const handleClick = useCallback(() => {
    openSentFaxDetailDrawer();
  }, [openSentFaxDetailDrawer]);

  const handleExited = useCallback(() => {
    onClose(faximoSendFaxId);
  }, [faximoSendFaxId, onClose]);

  //スナックバーが画面横から出てくるアニメーションを適応させるためuseEffectで通知を開く
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <ActionSnackbar
      open={isOpen}
      onExited={handleExited}
      isNotification={false}
      notifiedAt={notifiedAt}
      onClick={handleClick}
      onClose={handleClose}
    >
      <Icon color="white" icon="fax" size="xxl" />
      <Text color="white" size="xs" fontWeight="bold" marginLeft={theme.space.m}>
        FAXの送信に失敗しました。FAX番号を
        <br />
        お確かめの上もう一度お試し下さい。
      </Text>
    </ActionSnackbar>
  );
});

SendFaxErrorNotification.displayName = 'SendFaxErrorNotification';
