import { parse } from 'csv-parse/sync';

type MIME = 'unknown' | 'image/png' | 'image/gif' | 'image/jpeg' | 'application/pdf';

const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

export const download = (
  data: string | ArrayBuffer,
  fileName: string,
  type: string,
  extension: string,
  useBom = false,
) => {
  const blob = new Blob(useBom ? [BOM, data] : [data], { type });
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.${extension}`;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const downloadFromUrl = async (url: string, filename: string) => {
  const response = await fetch(url);
  const blob = await response.blob();

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const getExtensions = (mimes: string[]) => {
  let extensions: string[] = [];

  for (const mime of mimes) {
    switch (mime) {
      case 'image/*':
        extensions = extensions.concat(['jpg', 'png', 'gif']);
        break;
      case 'image/jpeg':
        extensions.push('jpg');
        break;
      case 'image/png':
        extensions.push('png');
        break;
      case 'application/pdf':
        extensions.push('pdf');
        break;
      case 'text/csv':
      case '.csv':
        extensions.push('csv');
        break;
    }
  }

  return extensions;
};

export const getFileHeader = async (file: File) => {
  return await new Promise<string>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const arr = new Uint8Array(fileReader.result as ArrayBuffer).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; ++i) {
        header += arr[i].toString(16);
      }
      resolve(header);
    };
    fileReader.readAsArrayBuffer(file);
  });
};

export const getMimeType = async (file: File) => {
  const header = await getFileHeader(file);

  // refs. https://en.wikipedia.org/wiki/List_of_file_signatures
  //       https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
  let type: MIME = 'unknown';
  switch (header) {
    case '89504e47':
      type = 'image/png';
      break;
    case '47494638':
      type = 'image/gif';
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
      type = 'image/jpeg';
      break;
    case '25504446':
      type = 'application/pdf';
      break;
    default:
      break;
  }

  return type;
};

const parseCsv = async (file: File) => {
  return await new Promise<string[][] | null>((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = () => {
      try {
        const records: string[][] = parse(fileReader.result?.toString() || '');
        resolve(records);
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    };
  });
};

/**
 * CSVはヘッダーから判定できないため、中身をパースして判定する
 * @param file
 */
export const isCsvFile = async (file: File) => {
  const result = await parseCsv(file);

  if (!result) return false;
  if (result.length > 0) {
    return true;
  }

  return false;
};

export const isPdf = (contentType: string) => contentType === 'application/pdf';
