import { useTheme } from '@emotion/react';
import { subMinutes } from 'date-fns';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Icon, Text } from '~/components/blocks';
import { PreparedTelemedicineAppointmentFragment } from '~/graphql';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import { Label } from '~/utils/label';

import { ActionSnackbar } from '../ActionSnackbar';
import { useOpenOnlineWindow } from './use-open-online_window';

const PREPARED_TELEMEDICINE_TIME_IN_MINUTES = 5;

type Props = {
  pharmacist: boolean;
  appointment: PreparedTelemedicineAppointmentFragment;
  onClose: (appointmentId: string) => void;
};

export const TelemedicineNotification = React.memo((props: Props) => {
  const { pharmacist, appointment, onClose } = props;
  const theme = useTheme();
  const router = useRouter();
  const { loading, handleOpenOnlineWindow } = useOpenOnlineWindow();

  const [isOpen, setIsOpen] = useState(false);
  const setDrawerState = useSetRecoilState(profileDrawerState);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const openOnlineWindow = useCallback(async () => {
    try {
      await handleOpenOnlineWindow(appointment);
      handleClose();
    } catch {
      // 何もしない
    }
  }, [appointment, handleClose, handleOpenOnlineWindow]);
  const openPatientDrawer = useCallback(() => {
    if (router.pathname !== '/reception') {
      router.push('/reception');
      // unmountが完了する前にページ遷移が完了すると通知が再表示されてしまう
      // 通知を管理してる大元のリストから削除し、通知が再表示されないようにする
      onClose(appointment.id);
    } else {
      handleClose();
    }

    if (appointment.patient) {
      setDrawerState({
        isOpen: true,
        appointmentId: appointment.id,
        patientId: appointment.patient.id,
        error: null,
      });
    }
  }, [appointment.id, appointment.patient, handleClose, onClose, router, setDrawerState]);
  const handleClick = useCallback(async () => {
    if (pharmacist) {
      await openOnlineWindow();
    } else {
      openPatientDrawer();
    }
  }, [openOnlineWindow, openPatientDrawer, pharmacist]);
  const handleExited = useCallback(() => {
    onClose(appointment.id);
  }, [appointment.id, onClose]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <ActionSnackbar
      open={isOpen}
      onExited={handleExited}
      isNotification={loading}
      notifiedAt={subMinutes(appointment.start!, PREPARED_TELEMEDICINE_TIME_IN_MINUTES)}
      onClick={handleClick}
      onClose={handleClose}
    >
      <Icon color="white" icon="online" size="xxl" />
      <Text color="white" size="xs" fontWeight="bold" marginLeft={theme.space.m}>
        {`${appointment.patient?.familyName}${appointment.patient?.givenName}様`}
        のオンライン服薬指導が
        {`${appointment.start ? Label.HHMM(appointment.start) : 'ー'}`}から始まります
      </Text>
    </ActionSnackbar>
  );
});

TelemedicineNotification.displayName = 'TelemedicineNotification';
