import { useTheme } from '@emotion/react';
import React from 'react';

import { Flex, Icon, Text, Tooltip } from '~/components/blocks';
import { AppointmentDeliveryMethod } from '~/graphql';
import { Size } from '~/styles/types';

type Props = {
  deliveryMethod: AppointmentDeliveryMethod;
  size: Size;
};

const UberEatsTextSize: Record<Size, Size> = {
  xxs: 'xxs',
  xs: 'xxs',
  s: 'xs',
  m: 's',
  l: 'm',
  xl: 'l',
  xxl: 'xl',
  xxxl: 'xxl',
};

export const DeliveryMethodField = React.memo(({ deliveryMethod, size }: Props) => {
  const theme = useTheme();

  if (deliveryMethod === 'hand') {
    return (
      <Flex alignItems="center">
        <Text fontWeight="bold" size={size} whiteSpace="nowrap">
          薬局にてお渡し
        </Text>
      </Flex>
    );
  }

  if (deliveryMethod === 'same_day_delivery') {
    return (
      <Flex alignItems="center">
        <Text fontWeight="bold" size={size} whiteSpace="nowrap">
          当日配達
        </Text>
        <Text size={UberEatsTextSize[size]} whiteSpace="nowrap">
          （Uber Eats）
        </Text>
        <Tooltip
          placement="top"
          content={
            <>
              Uber Eatsの配達員が、お薬を患者住所へ
              <br />
              服薬指導当日中にお届けするサービスです
            </>
          }
        >
          <Flex marginLeft={theme.space.xs}>
            <Icon icon="hint" size="m" color="navy" />
          </Flex>
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <Text fontWeight="bold" size={size} whiteSpace="nowrap">
        通常配達
      </Text>
      <Tooltip placement="top" content={<>薬局にて対応可能な方法で配送を手配してください</>}>
        <Flex marginLeft={theme.space.xs}>
          <Icon icon="hint" size="m" color="navy" />
        </Flex>
      </Tooltip>
    </Flex>
  );
});

DeliveryMethodField.displayName = 'DeliveryMethodField';
