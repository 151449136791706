import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Alert, Flex, Grid, Icon, Text } from '~/components/blocks';
import { useFeatureFlag } from '~/hooks/use-feature-flag';

type Props = {
  children: React.ReactNode;
};

const Link = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

// TODO: Uber無料キャンペーンが終わりFeatureFlagの制御が入らなくなったタイミングで削除する
export const UberFreeInformation = (props: Props) => {
  const displayUberFreePromotion = useFeatureFlag('Issue9813-before-promotion');
  const { children } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });

  if (displayUberFreePromotion) {
    return (
      <Grid height="100%" gridTemplateRows="min-content 1fr">
        <Alert
          status="info"
          marginTop={theme.space.l}
          marginLeft={theme.space.xl}
          marginRight={theme.space.xl}
        >
          <Flex alignItems="center">
            <Text size={isTablet ? 'xs' : 's'}>
              2024年10月1日（火）〜
              10月31日（木）の間、当日配達料無料キャンペーンを開催！この機会に当日配達機能の利用をご検討ください（
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://intercom.help/pharms/ja/articles/9765203"
              >
                キャンペーン詳細
              </Link>
              <Icon
                icon="blank"
                size={isTablet ? 's' : 'm'}
                marginLeft={theme.space.m}
                marginRight={theme.space.m}
                color="linkBlue"
              />
              ）
            </Text>
          </Flex>
        </Alert>
        {children}
      </Grid>
    );
  }

  return <>{children}</>;
};
