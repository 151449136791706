import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Property } from 'node_modules/csstype';
import React from 'react';
import {
  compose,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  variant,
} from 'styled-system';

import { ColorPalette, MonoColor } from '~/styles/theme';
import { Size } from '~/styles/types';

type Props = FontWeightProps &
  LineHeightProps &
  SpaceProps &
  TextAlignProps & {
    size?: Size;
    block?: boolean;
    userSelect?: boolean;
    as?: React.ElementType;
    whiteSpace?: Property.WhiteSpace;
    lineHeight?: Property.LineHeight;
    wordBreak?: Property.WordBreak;
    color?: keyof typeof ColorPalette | keyof typeof MonoColor;
  };

export const Text = styled('p', {
  shouldForwardProp,
})<Props>(
  ({ whiteSpace, wordBreak, block, color, userSelect }) =>
    css({
      whiteSpace,
      wordBreak,
      display: block ? 'block' : 'inline-block',
      userSelect: userSelect ? 'none' : 'auto',
      margin: 0,
      padding: 0,
      color: color
        ? ColorPalette[color as keyof typeof ColorPalette] ||
          MonoColor[color as keyof typeof MonoColor]
        : MonoColor.grey05,
    }),
  ({ theme }) =>
    compose(
      fontWeight,
      space,
      textAlign,
      lineHeight,
      variant({
        prop: 'size',
        variants: {
          xxs: css({
            fontSize: theme.fontSizes.xxs,
          }),
          xs: css({
            fontSize: theme.fontSizes.xs,
          }),
          s: css({
            fontSize: theme.fontSizes.s,
          }),
          m: css({
            fontSize: theme.fontSizes.m,
            [`@media ${theme.mediaQueries.tablet}`]: {
              fontSize: theme.fontSizes.s,
            },
          }),
          l: css({
            fontSize: theme.fontSizes.l,
            [`@media ${theme.mediaQueries.tablet}`]: {
              fontSize: theme.fontSizes.m,
            },
          }),
          xl: css({
            fontSize: theme.fontSizes.xl,
            [`@media ${theme.mediaQueries.tablet}`]: {
              fontSize: theme.fontSizes.l,
            },
          }),
          xxl: css({
            fontSize: theme.fontSizes.xxl,
          }),
        },
      }),
    ),
);

Text.defaultProps = {
  color: 'black',
};
