import { useTheme } from '@emotion/react';
import React, { useRef } from 'react';

import { Box, Grid, ScrollBox, Text } from '~/components/blocks';
import {
  DispensingRequestCollapse,
  InsuranceCardCollapse,
  QuestionnaireSheet,
} from '~/components/partials';
import { PatientDetailDialogProfileFragment } from '~/graphql';

import { PatientProfile } from './PatientProfile';

type Props = {
  patient: PatientDetailDialogProfileFragment;
};

export const PatientKarte = React.memo((props: Props) => {
  const { patient } = props;
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const appointment = patient.appointments.nodes[0];
  const visitDay = appointment.start;

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="min-content min-content 1fr">
      <PatientProfile patient={patient} visitedAt={visitDay || undefined} />
      <Box marginTop={theme.space.m}>
        {appointment && (
          <DispensingRequestCollapse printable={false} appointmentId={appointment.id} />
        )}
        <InsuranceCardCollapse
          patientId={patient.id}
          isActivePatient={patient?.active}
          qualificationConfirmationMethod={appointment.qualificationConfirmationMethod}
        />
      </Box>
      <ScrollBox ref={scrollRef}>
        <Box marginTop={theme.space.l}>
          <Text fontWeight="bold" marginBottom={theme.space.m}>
            問診
          </Text>
          <QuestionnaireSheet loading={false} sheet={patient.patientSheet || undefined} />
        </Box>
      </ScrollBox>
    </Grid>
  );
});

PatientKarte.displayName = 'PatientKarte';
