import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import IcomoonReact from 'icomoon-react';
import React from 'react';
import { compose, margin, MarginProps } from 'styled-system';

import { ColorPalette, fontSizes } from '~/styles/theme';

import iconSet from './selection.json';

type Color = keyof typeof ColorPalette;
type Font = keyof typeof fontSizes;

export type IconName =
  | 'alert'
  | 'address'
  | 'agreement'
  | 'arrow-top'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'app'
  | 'audit'
  | 'back'
  | 'back-line'
  | 'blank'
  | 'checkin'
  | 'info'
  | 'check'
  | 'plus'
  | 'close'
  | 'close2'
  | 'company-list'
  | 'company-payout'
  | 'copy'
  | 'attention'
  | 'chat'
  | 'calender'
  | 'credit'
  | 'display'
  | 'delivery'
  | 'document'
  | 'download'
  | 'drug'
  | 'edit'
  | 'favorite'
  | 'favorite-fill'
  | 'fax'
  | 'forward-line'
  | 'help'
  | 'hint'
  | 'insurance'
  | 'lock'
  | 'logo'
  | 'unlock'
  | 'mail'
  | 'medicalnote-notification'
  | 'medicalnote'
  | 'more'
  | 'notification'
  | 'patient'
  | 'patient-checkin'
  | 'pdf'
  | 'print'
  | 'people'
  | 'qrcode'
  | 'reception'
  | 'search'
  | 'settings'
  | 'setting-gear'
  | 'sms'
  | 'sync'
  | 'not-sync'
  | 'tel'
  | 'trash'
  | 'uber'
  | 'uber-alert'
  | 'upload'
  | 'user-chat'
  | 'comment'
  | 'human'
  | 'online'
  | 'prescription'
  | 'time'
  | 'url'
  | 'map'
  | 'send'
  | 'smartphone'
  | 'followup'
  | 'pw-reset'
  | 'techo'
  | 'timer'
  | 'web-human'
  | 'error';

type Props = MarginProps & {
  color?: Color;
  size?: Font;
  icon: IconName;
  className?: string;
};

const marginProps = [
  'm',
  'margin',
  'mt',
  'marginTop',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mr',
  'marginRight',
  'my',
  'marginY',
  'mx',
  'marginX',
];

const Icons = styled(IcomoonReact, {
  shouldForwardProp: (props) => !marginProps.includes(props),
})<MarginProps>(
  () =>
    css({
      flexShrink: 0,
    }),
  () => compose(margin),
);

export const Icon = (props: Props) => {
  const { color, size, icon, className = '', ...styles } = props;

  return (
    <Icons
      {...styles}
      className={className}
      iconSet={iconSet}
      color={color && ColorPalette[color]}
      size={size && fontSizes[size]}
      icon={`icon-${icon}`}
    />
  );
};
