import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  EntryList,
  Flex,
  Grid,
  Icon,
  Text,
  Tooltip,
} from '~/components/blocks';
import { CsClinic } from '~/components/partials';
import { FollowupQuestionnairePreview } from '~/components/partials/FollowupQuestionnairePreview';
import { CsClinicItemFragment, MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { Fields } from '../InputPane/types';
import { Warning } from './Warning';

type Props = {
  sending: boolean;
  formik: FormikProps<Fields>;
  clinic: CsClinicItemFragment | null;
  questionnaire: MedicationFollowupQuestionnairesSelectFragment | null;
  onBack: () => void;
  onSubmit: (values: Fields, formik: FormikProps<Fields>) => void;
};

const ConfirmationList = styled(EntryList)(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.m,
      borderTop: theme.borders.default,
      paddingTop: theme.space.m,
    },
  }),
);

const AutoReplyBox = styled('div')(({ theme }) =>
  css({
    marginTop: theme.space.m,
    borderTop: theme.borders.default,
    paddingTop: theme.space.m,
    paddingBottom: theme.space.m,
  }),
);

const AutReplyList = styled(EntryList)(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

const PreviewText = styled('div')(({ theme }) =>
  css({
    textDecoration: 'underline',
    fontSize: theme.fontSizes.xs,
    cursor: 'default',
    marginLeft: theme.space.m,
  }),
);

export const ConfirmPane = React.memo((props: Props) => {
  const { sending, formik, questionnaire } = props;
  const theme = useTheme();

  const [isConsented, setIsConsented] = useState(false);

  const values = formik.values;

  const handleClick = useCallback(() => setIsConsented((_state) => !_state), []);
  const handleSubmit = useCallback(() => {
    props.onSubmit(formik.values, formik);
    formik.resetForm();
  }, [formik, props]);

  return (
    <Grid
      height="100%"
      overflow="hidden"
      gridTemplateRows="min-content min-content 1fr min-content"
    >
      <Warning />
      <Box height="100%" overflow="auto">
        <ConfirmationList size="s">
          <EntryList.Head>送信日時</EntryList.Head>
          <EntryList.Body>
            <Text fontWeight="bold">
              {values.fixed
                ? values.sendDate
                  ? `${Label.YYYYMMDDja(values.sendDate)} ${values.sendTime}:00`
                  : '送信日時を選択してください'
                : '即時配信'}
            </Text>
          </EntryList.Body>
        </ConfirmationList>
        <ConfirmationList size="s">
          <EntryList.Head>送信内容</EntryList.Head>
          <EntryList.Body>
            <Text size="s" whiteSpace="pre-line">
              {values.message}
            </Text>
          </EntryList.Body>
        </ConfirmationList>
        {values.attachment === 'clinic' ? (
          <EntryList size="s" paddingTop={theme.space.m}>
            <EntryList.Head>案内する医療機関</EntryList.Head>
            <EntryList.Body>
              {props.clinic && (
                <Box marginTop={theme.space.m}>
                  <CsClinic clinic={props.clinic} />
                </Box>
              )}
            </EntryList.Body>
          </EntryList>
        ) : values.attachment === 'questionnaire_sheet' ? (
          <>
            <EntryList size="s" paddingTop={theme.space.m}>
              <EntryList.Head>質問票</EntryList.Head>
              <EntryList.Body>
                <Flex alignItems="center">
                  <Text paddingRight={theme.space.l}>{questionnaire?.revision.subject}</Text>
                  <Tooltip
                    content={
                      <FollowupQuestionnairePreview
                        content={
                          <Text size="xs" fontWeight="bold" color="white">
                            メッセージと合わせて、
                            <br />
                            以下の質問内容が患者に送信されます。
                          </Text>
                        }
                        questionnaire={questionnaire}
                      />
                    }
                    placement="top"
                  >
                    <PreviewText>質問内容を確認</PreviewText>
                  </Tooltip>
                </Flex>
              </EntryList.Body>
            </EntryList>
            <AutoReplyBox>
              <Box marginTop={theme.space.s} marginBottom={theme.space.s}>
                <Text fontWeight="bold" size="s">
                  患者への自動返信メッセージ
                </Text>
              </Box>
              {formik.values.isEnabledAutoReplySetting ? (
                <>
                  <AutReplyList size="s">
                    <EntryList.Head>連絡フォーム</EntryList.Head>
                    <EntryList.Body>
                      {formik.values.withAutoReplyQuestionnaire ? 'あり' : 'なし'}
                    </EntryList.Body>
                  </AutReplyList>
                  <AutReplyList size="s">
                    <EntryList.Head>メッセージ内容</EntryList.Head>
                    <EntryList.Body>{formik.values.autoReplyMessage}</EntryList.Body>
                  </AutReplyList>
                </>
              ) : (
                <Text size="s">送信しない</Text>
              )}
            </AutoReplyBox>
          </>
        ) : null}
        <Flex marginTop={theme.space.m}>
          <Button size="xs" use="white" disabled={sending} onClick={props.onBack}>
            <Icon icon="back-line" size="l" />
            <Text size="xs">戻る</Text>
          </Button>
        </Flex>
      </Box>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        marginTop="auto"
        position="sticky"
        style={{ background: 'white' }}
      >
        <Checkbox
          disabled={sending}
          label="患者からメッセージ送信を同意済"
          checked={isConsented}
          onChange={handleClick}
        />
        <Button
          use="secondary"
          disabled={!isConsented}
          loading={sending}
          marginLeft={theme.space.l}
          onClick={handleSubmit}
        >
          送信
        </Button>
      </Flex>
    </Grid>
  );
});

ConfirmPane.displayName = 'ConfirmPane';
