export const FormErrorMessage = {
  unauthenticated: 'ログインに失敗しました\nユーザーIDまたはパスワードに誤りがあります',
  required: '${path}を入力してください',
  invalid: '${path}を正しく入力してください',
  invalidBithDate: '生年月日に未来日は指定できません',
  minLengthRequiredAlpha: '半角英数${min}文字以上で入力してください',
  minLengthRequiredAlphaSymbol: '半角英数記号${min}文字以上で入力してください',
  number: '数字を入力してください',
  time: '時間を入力してください',
  alpha: '半角英数のみで入力してください',
  alphaSymbol: '半角英数記号のみで入力してください',
  maxLengthRequired: '${max}文字以内で入力してください',
  passwordMismatch: 'パスワードが一致しません',
  mismatch: '${path}が一致しません',
  map: '住所を地図に反映してください',
  length: '${length}文字入力してください',
  min: '${min}以上を入力してください',
  max: '${max}以下を入力してください',
  minLength: '${label}を${min}つ以上選択してください',
  minLengthAddition: '${label}を${min}つ以上追加してください',
  accountHolderName:
    '全角英数カタカナ、記号(全角スペース、全角カッコ、全角ピリオド、全角マイナス)のみを入力してください',
  addressNotFound: '住所が見つかりません',
  bankingFacilityNotFound: '金融機関が見つかりません',
  requiredPostalCode: '郵便番号を入力してください',
  integer: '整数で入力してください',
  integerRange: (min: number, max: number) => `${min}〜${max}の整数で入力してください`,
  invalidPostalCode: '郵便番号を正しく入力してください',
  katakana: '全角カタカナで入力してください',
  minMaxLengthRequired: (min: number, max: number) => `${min}〜${max}文字で入力してください`,
  beforeEndTime: '開始時間は終了時間より前の時間を入力してください',
  duplicatedTime: '受付時間は重ならないように入力してください',
  maxCountRequired: '${label}は${max}つ以内で入力してください',
  invalidClientAuthStartAt: '当日以降の日付を指定してください',
  unique: '${label}は一意である必要があります',
  selectionRequired: '${label}を選択してください',
  duplicatedLimitTime: '受付上限の時間は重ならないように入力してください',
  outsideTime: '受付時間内の時間を指定してください',
  emailInvalid: '入力形式が正しくありません',
  corporateNumberInvalid: '数字13桁で入力してください',
  ipInvalid: '正しい形式で入力してください',
  onlineQualificationConfirmationUrlInvalid:
    'オンライン資格確認等システムにて発行されたURLを入力してください',
};

export const ImportErrorMessage = {
  formatError: 'インポート可能な形式ではありません',
};
